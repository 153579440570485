import React, { Component } from 'react';
import logo from './ayo_logo_v6_FINAL.png';
import cartoonPic from './ux/Tolu_Alicia_Cartoon_Logo.png';
import valuesPic from './ux/values_cloud.png';
//import ResponsiveMenu from 'react-responsive-navbar';
import ResponsiveMenu from './ResponsiveMenuClose';
import { StickyContainer, Sticky } from 'react-sticky';
import styled from 'styled-components';

import { FaBars, FaWindowClose, FaInfoCircle, FaLaptopCode, FaArrowDown, FaChartBar } from 'react-icons/fa';
import { GiWalkingBoot } from "react-icons/gi";

import { Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

import {Link} from 'react-router-dom';

import './App.css';

/* import mobiscroll */
import mobiscroll from "@mobiscroll/react-lite";
//import "@mobiscroll/react-lite/dist/css/mobiscroll.min.css";


mobiscroll.settings = {
    theme:"ios"
}

const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 100;
 `;

const Menu = styled.div`
  ul {
    padding: 0;
  }
  li {
    display: inline-block;
    font-size: 13px;
    list-style-type: none;
    margin-left: 30px;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    color: black;
    &:hover {
      color: blue;
    }
  }
  @media (max-width: 500px) {
    padding: 10px 0;
    li {
      padding: 10px 0;
      display: block;
      margin-left: 0;
    }
  }
`;

const TextContent = styled.div` 
  text-align: center;
  font-size: ${props => props.fontSize ? props.fontSize : "24px"};
  font-family: ${props => props.font ? props.font : "Arial, Helvetica, sans-serif;"}; 
  color: ${props => props.white ? "white" : "black"};
  
`;

const TextContentSpace = styled.div` 
  text-align: center;
  font-size: ${props => props.fontSize ? props.fontSize : "24px"};
  font-family: ${props => props.font ? props.font : "Arial, Helvetica, sans-serif;"}; 
  color: ${props => props.white ? "white" : "black"};
  padding-top: 40px;
  
`;

const ConnectBlock = styled.div` 
  background-color: ${props => props.alternate ? props.alternate : "dimgray"};
  padding-top: 30px;
  padding-bottom: 30px; 
`;

const HyperLink = styled.a` 
  color: white !important;
`;

const CartoonBlock = styled.div` 
  background-color: white;
  padding-top: 30px;
  padding-bottom: 30px; 

  img {
    display: block;
    width: 955px;
    margin: 0 auto 0;
  }
`;

const BackPicBlock = styled.div` 
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props => props.pic ? `url(${props.pic})` : "url(./analysis-blackboard-board-bubble-355952.jpg)"}; 
`;

class ConsultAlicia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
      loading: true,
      showMenu: false
    };
    
  }


  componentDidMount() {
    this.setState({
      loading: false,
    })

    const x = this; // need to get correct this context  for use in event below

    Events.scrollEvent.register('begin', function(to, element) {
      console.log("begin", arguments);
    });
 
    Events.scrollEvent.register('end', function(to, element) {
      console.log("end", arguments);
      //console.log("to: ", to);
      //console.log("element: ", element);
      //setTimeout(() => x.handleToggleMenu(), 500)
      //x.handleToggleMenu();
    });
 
    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleSetActive = (myScrollToElement) =>  {

    scroller.scrollTo(myScrollToElement, {
      duration: 800,
      delay: 0,
      smooth: true,
      ignoreCancelEvents: true
    })
    
  }

  handleToggleMenu = () =>  {

    
    if(this.state.showMenu){
      console.log("before1 showMenu: " + this.state.showMenu);
      this.setState({ showMenu: false }, ()=>console.log("after1 showMenu: " + this.state.showMenu));
    }
    else{
      console.log("before2 showMenu: " + this.state.showMenu);
      this.setState({ showMenu: true }, ()=>console.log("after2 showMenu: " + this.state.showMenu));
    }

    
  }

  render() {
    const { isVisible } = this.state;
    
    return (
      <mobiscroll.Form className="md-grid-basic" theme="ios"  themeVariant="light">
        <mobiscroll.FormGroup >
        
          <div className="mbsc-grid">
            <div className="mbsc-row">
              {/* <StickyContainer className="container">
                <Sticky>
                  {({ style }) => (
                    <Header style={style} renderCount={renderCount++} />
                  )}
                </Sticky>

                <h2 className="text-center">{"<StickyContainer />"}</h2>
              </StickyContainer> */}
              
              <div className="mbsc-col-3 mbsc-col-md-8 mbsc-col-lg-8 ayo-menu">
                <ResponsiveMenu
                  menuOpenButton={<FaBars size={30} />}
                  menuCloseButton={<FaWindowClose size={30} />}
                  changeMenuOn="500px"
                  largeMenuClassName="large-menu"
                  smallMenuClassName="small-menu"
                  showMenu={this.state.showMenu}
                  onClick={() => this.handleToggleMenu()}
                  menu={
                    <Menu>
                      <ul>
                        <li>
                        <Link to="/">Home</Link>
                        </li>
                       {/*  <li>
                        <a role="button" onClick={() => this.handleSetActive("anchor2")}>ARC</a>                    
                        </li> */}
                        {/* <li>
                        <a role="button" onClick={() => this.handleSetActive("anchor3")}>Dance</a>
                        </li> */}                          
                      </ul>
                    </Menu>
                  }
                />
              </div>
              <div className="mbsc-col-9 mbsc-col-md-4 msbsc-col-lg-4"><img src={logo} alt="logo" className="ayo-logo" /></div>
            </div>
            
          </div>
        </mobiscroll.FormGroup>

        <mobiscroll.FormGroup>

         
          <Element name="anchor1"></Element>
          <div className="mbsc-grid ayo-grid">
            <div className="mbsc-row mbsc-justify-content-center ayo-chalkboard">
              <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-12 ayo-no-padding">
                <TextContent fontSize="28px" white>ARC Consulting Services</TextContent>
              </div>
              <div className="mbsc-padding mbsc-col-12 ayo-text-align ayo-text-1"><FaArrowDown size={40} color="White" /></div>
            </div>    
          </div>
        </mobiscroll.FormGroup>

        <mobiscroll.FormGroup>
        <Element name="anchor2"></Element>
          
          <div className="mbsc-grid ayo-grid">       

            <ConnectBlock alternate="black" className="mbsc-row">  
              <div className="mbsc-col-12 ayo-text-align ayo-text-1"><FaChartBar size={40} color="White" /></div>            
              
              <div className="mbsc-padding mbsc-col-12 ayo-text-1">
                <p className="mbsc-txt-l mbsc-align-center">Need help with Data Analysis?</p>
                <p className="mbsc-txt-m mbsc-align-center">Fill out our <HyperLink target="_blank" href="https://forms.gle/Cmv9hobTqVDeBUas9">ARC Intake Form</HyperLink> and we can see if we can help!</p>
              </div>
            </ConnectBlock>
            {/* <Element name="anchor3"></Element>
            <ConnectBlock className="mbsc-row">  
              <div className="mbsc-col-12 ayo-text-align ayo-text-1"><GiWalkingBoot size={40} color="White" /></div>            
            
              <div className="mbsc-padding mbsc-col-12 ayo-text-1">
                <p className="mbsc-txt-l mbsc-align-center">Need help with dance?</p>
                <p className="mbsc-txt-m mbsc-align-center">We provide consulting on professional choreography in Hip Hop, Jazz and various other dance expressions</p>
                <p className="mbsc-txt-m mbsc-align-center"><HyperLink target="_blank" href="mailto:dance@ayoboard.com">dance@ayoboard.com</HyperLink></p>
              </div>
            </ConnectBlock>  */}

              
            
          </div>
        </mobiscroll.FormGroup>

        <mobiscroll.FormGroup>
         

          
          <div className="mbsc-grid ayo-grid">
            <ConnectBlock alternate="#e9e2e8" className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6 ayo-no-padding">
                <img src={valuesPic} alt="logo" className="ayo-blackboard" />
              </div>
            </ConnectBlock>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-8 mbsc-col-md-4 mbsc-col-lg-4">
                <img src={cartoonPic} alt="cartoon" className="ayo-blackboard" />
              </div>
            </div>
          </div>
        </mobiscroll.FormGroup>
      
      </mobiscroll.Form>
    );


  }
}

export default ConsultAlicia;
