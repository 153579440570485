import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './index.css';
import "@mobiscroll/react-lite/dist/css/mobiscroll.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import App from './App';
import Properties from './Properties';
import ConsultAlicia from './ConsultAlicia';
import Notfound from './notfound';
import * as serviceWorker from './serviceWorker';

const routing = (
    <Router>

        <Switch>
            <Route exact path="/" component={App} />
            <Route exact path="/properties" component={Properties} />
            <Route exact path="/arc" component={ConsultAlicia} />
            <Route exact path='/board' component={() => {
                window.location.href = '/board';
                return null;
            }} />
            <Route exact path='/blog' component={() => {
                window.location.href = '/blog';
                return null;
            }} />
            <Route component={Notfound} />
        </Switch>

    </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
