import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SmallMenu = styled.div`
  display: none;
  text-align: center;
  @media (max-width: ${props => props.size}) {
    display: block;
  }
`;

const LargeMenu = styled.div`
  display: block;
  text-align: center;
  @media (max-width: ${props => props.size}) {
    display: none;
  }
`;

const MenuIcon = ({ onClick, icon }) => (
  <div role="button" onClick={() => onClick()}>
    {icon}
  </div>
);

class ResponsiveMenuClose extends Component {
  constructor(props) {
    super(props);
    
  }

  /*handleClick = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };*/

  render() {
    const {
      menu,
      largeMenuClassName,
      smallMenuClassName,
      changeMenuOn,
      menuOpenButton,
      menuCloseButton
    } = this.props;
    console.log("ResponsiveMenuClose: " + this.props.showMenu);
    return (
      <div>
        <LargeMenu className={largeMenuClassName} size={changeMenuOn}>
          {menu}
        </LargeMenu>
        <SmallMenu className={smallMenuClassName} size={changeMenuOn}>
          {!this.props.showMenu ? (
            <MenuIcon onClick={() => this.props.onClick()} icon={menuOpenButton} />
          ) : (
            <MenuIcon onClick={() => this.props.onClick()} icon={menuCloseButton} /> // this.handleClick
          )}
          {this.props.showMenu ? <div>{menu}</div> : null}
        </SmallMenu>
      </div>
    );
  }
}
ResponsiveMenuClose.propTypes = {
  menu: PropTypes.node.isRequired,
  largeMenuClassName: PropTypes.string,
  smallMenuClassName: PropTypes.string,
  changeMenuOn: PropTypes.string.isRequired,
  menuOpenButton: PropTypes.node.isRequired,
  menuCloseButton: PropTypes.node.isRequired
};

ResponsiveMenuClose.defaultProps = {
  largeMenuClassName: '',
  smallMenuClassName: ''
};
export default ResponsiveMenuClose;